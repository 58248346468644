import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";


export default class ApiProduct extends BaseApiService {

    async received(shop,key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/shops/"+shop+'/'+key+"/received");
        return await this.callApi();
    }

    async send(key,shop) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/shops/"+shop+'/'+key+"/sent");
        return await this.callApi();
    }

    async destroy(key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/"+key+"/destroyed");
        return await this.callApi();
    }

    async lost(key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/"+key+"/lost");
        return await this.callApi();
    }

    async stolen(key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/"+key+"/stolen");
        return await this.callApi();
    }

    async getToken(sealVector,sku,product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody({
            sealVector,
            sku,
            product
        });
        this.setApiRoute(sessionStorage['language'] + "/products/generateToken");
        return await this.callApi();
    }

    async read(gtin, serial) {
        this.setGetMethod();
        this.setSecureByService();
        this.setApiRoute(sessionStorage['language'] + "/products/"+gtin+"/"+serial);
        return await this.callApi();
    }

    async listFromModel(key) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products_model/"+key);
        return await this.callApi();
    }

    async listState(state) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/list_state/" + state);
        return await this.callApi();
    }
    async validate(product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/validate");
        return await this.callApi();
    }

    async validateInvoice(product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/validateInvoice");
        return await this.callApi();
    }

    async refuseInvoice(product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/refuseInvoice");
        return await this.callApi();
    }

    async linkToModel(product, model) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/model/" + model);
        return await this.callApi();
    }

    async sell(product, address) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(address);
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/sell");
        return await this.callApi();
    }

    async sellAndCreate(product, form) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(form);
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/sellAndCreate");
        return await this.callApi();
    }
}
