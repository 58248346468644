import React from "react";
import PasswordAutoform from "./AutoFormInput/PasswordAutoform";
import EmailAutoForm from "./AutoFormInput/EmailAutoForm";
import StringAutoform from "./AutoFormInput/StringAutoform";
import IntegerAutoform from "./AutoFormInput/IntegerAutoform";
import SelectAutoform from "./AutoFormInput/SelectAutoform";
import BooleanAutoform from "./AutoFormInput/BooleanAutoform";
export default class Autoform extends React.Component {
    #state
    constructor(objectJson,functionCallBack) {
        super(objectJson);
        this.#state = {
            notUsed: objectJson[0].notUsed,
            submitCallback: objectJson[0].submitCallback,
            arrayFinal : objectJson[0].arrayFinal,
            dataModal : objectJson[0].dataModal,
            idModal : objectJson[0].idModal,
            method : objectJson[0].declaration,
            redirection : "",
            functionCallBack : functionCallBack,
            name : objectJson[0].name,
            keyForm : objectJson[0].keyName,
            langForm : objectJson[0].langForm,
            groupsForm : objectJson[0].groupsForm,
            model : objectJson[0].model,
            modale : objectJson[0].modale, // savoir si le param modale est a true pour en créer une
            classInput : objectJson[0].classInput,
            arrayForm : Object.values(objectJson[0].data), // value des date envoyé
            arrayFormKeys : Object.keys(objectJson[0].data), // clé des data envoyé
            routeAxios : objectJson[0].route,// route
            methodRoute : objectJson[0].methodRoute, // GET : POST : PUT
            childComponents : [],
            modaleHeader : objectJson[0].modaleHeader,
            hidden : objectJson[0].hidden,
            submit : objectJson[0].submit,
            cancel : objectJson[0].cancel ,
            actualyInput : "",
            status : objectJson[0].status,
            form : document.querySelectorAll('.formthis')
        };



        (this.#state.arrayForm) ? (
                this.#state.arrayForm.map( (form, index) => {
                    var parameter = [{
                        parameterName : this.#state.name,
                        parameterObject : this.#state.arrayForm[index],
                        parameterkeyForm: this.#state.keyForm,
                        parameterData : this.#state.arrayFormKeys[index],
                        modale : this.#state.modale,
                        parameterClass : this.#state.classInput,
                    }];
                    if(!this.#state.notUsed || !this.#state.notUsed.includes(parameter[0].parameterData)) {
                        switch (form.type) {
                            case 'string':
                                var string = new StringAutoform(parameter[0]);
                                this.#state.childComponents.push(string);
                                return
                            case 'integer':
                                var string = new IntegerAutoform(parameter[0]);
                                this.#state.childComponents.push(string);
                                return
                            case 'email':
                                var string = new EmailAutoForm(parameter[0]);
                                this.#state.childComponents.push(string);
                                return
                            case 'setting':
                                var string = new SelectAutoform(parameter[0]);

                                this.#state.childComponents.push(string);
                                return
                            case 'boolean':
                                var string = new BooleanAutoform(parameter[0]);
                                this.#state.childComponents.push(string);
                                return
                            case 'password':
                                var string = new PasswordAutoform(parameter[0]);
                                this.#state.childComponents.push(string);
                                return
                            default:
                                return null;
                        }
                    }
                })
            ) :
            ( ''
            )

    }


    render() {
        if (this.#state.childComponents) {
            return (
                <>
                    <div id={`${ this.#state.modale == true ? 'skModal' : ""}`} className={`banner ${ this.#state.modale == true ? "modal fade" : ""} ${this.#state.modale == true ? 'skModal'  : ""}`}>
                        <div className={`banner ${ this.#state.modale == true ? "modal-dialog" : ""}`}>
                            <div className={`banner ${ this.#state.modale == true ? "modal-content" : ""}`}>
                                { (this.#state.modaleHeader) ? (
                                    <div className="modal-header">
                                        <input type="hidden" id="" defaultValue={0}/>
                                        <strong>{this.#state.modaleHeader}</strong>
                                        <button
                                            type="button"
                                            className="closeModal close"
                                            onClick={() => this.closeModal()}
                                            data-dismiss="modal"
                                            aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                ) : "" }
                                <div className={`banner ${ this.#state.modale == true ? "modal-body" : ""}`}>

                                    <form onSubmit="return false" onSubmit = {this.#submit}  className={this.#state.keyForm + "-" + this.#state.name + " formthis"} >
                                        {/*construction du form*/}
                                        { this.#state.childComponents.map(input =>
                                            (input.render()) ? (
                                                input.render()
                                            ) : ( 'ok'
                                            )
                                        )}
                                        {/*fin de la consturction*/}
                                        { (this.#state.hidden) ? (
                                            <div><input className={'hiddenInput'} type="hidden"/> </div>
                                        ) : "" }
                                        <button
                                            className={(this.#state.modale ? 'btn btn-primary ' : '') + 'submit' + '-' + this.#state.keyForm + "-" + this.#state.name }
                                            onClick={(e) => {this.#submit(e)}}
                                        > {this.#state.submit}

                                        </button>
                                        { (this.#state.cancel) ? (
                                            <button  onClick={() => this.closeModal()} className={'btn btn-secondary btncancel cancel' + '-' + this.#state.keyForm + "-" + this.#state.name} type="button"> {this.#state.cancel} </button>
                                        ) : "" }
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    #submit(e) {
        e.preventDefault()
        e.stopPropagation()
        const acceptRequete = async event => {
            if (this.#state.arrayFinal) {
                const declarationCallback = new this.#state.functionCallBack;
                var result = await declarationCallback[this.#state.method](...this.#state.arrayFinal,checkTableInput)
                if (this.#state.submitCallback){
                    this.#state.submitCallback();
                }
                if (result.getCode() === 0) {
                    window.location.reload()
                }
            }
            else if (this.#state.method == "delete") {
                const declarationCallback = new this.#state.functionCallBack;
                var result = await declarationCallback[this.#state.method](this.#state.langForm,this.#state.groupsForm,this.#state.idModal)
                if (this.#state.submitCallback){
                    this.#state.submitCallback();
                }
                if (result.getCode() === 0) {
                    window.location.reload()

                }
            }
            else if(this.#state.groupsForm && this.#state.idModal) {
                const declarationCallback = new this.#state.functionCallBack;
                var result = await declarationCallback[this.#state.method](this.#state.langForm,this.#state.groupsForm,this.#state.idModal,checkTableInput)
                if (this.#state.submitCallback){
                    this.#state.submitCallback();
                }
                if (result.getCode() === 0) {
                    window.location.reload()
                }
            }
            else if (this.#state.idModal) {
                const declarationCallback = new this.#state.functionCallBack;
                var result = await declarationCallback[this.#state.method](this.#state.idModal,checkTableInput)
                if (this.#state.submitCallback){
                    this.#state.submitCallback();
                }
                if (result.getCode() === 0) {
                    window.location.reload()
                }
            }
            else if(this.#state.groupsForm) {
                const declarationCallback = new this.#state.functionCallBack;
                var result = await declarationCallback[this.#state.method](this.#state.langForm, this.#state.groupsForm, checkTableInput)
                if (this.#state.submitCallback){
                    this.#state.submitCallback();
                }
                if (result.getCode() === 0) {
                    window.location.reload()
                }
            }
            else {
                const declarationCallback = new this.#state.functionCallBack;
                var result = await declarationCallback[this.#state.method](checkTableInput)
                if (this.#state.submitCallback){
                    this.#state.submitCallback();
                }
                if (result.getCode() === 0) {
                    window.location.reload()
                }
            }
        }

        var body = document.querySelector('body')
        body.style.cursor = "wait"
        var checkTableInput = {};
        var erorCode = [];
        var acceptSubmit = true;
        if (this.#state.childComponents) {
            for (let i = 0; i < this.#state.childComponents.length ; i++) {
                checkTableInput[this.#state.childComponents[i].check()[1]] = this.#state.childComponents[i].check()[2]
                if (this.#state.childComponents[i].check()[0] === false) {
                    erorCode.push(this.#state.childComponents[i].check()[1])
                    acceptSubmit = false;
                }
            }
        }
        if (acceptSubmit !== true) {
            for (let i = 0; i < erorCode.length ; i++) {
                alert(erorCode[i])
            }
            body.style.cursor = "inherit"
        }
        if (acceptSubmit === true && this.#state.status === "refresh") {
            body.style.cursor = "inherit"
            acceptRequete();
            location.href = location.href;

        }
        if (acceptSubmit === true && this.#state.status === "redirection") {
            acceptRequete();
            body.style.cursor = "inherit"
            location.href = this.#state.redirection;
        }
        if (acceptSubmit === true && this.#state.status !== "redirection" && this.#state.status !== "refresh") {
            acceptRequete();
            body.style.cursor = "inherit"
            return false;
        }
    }

    openModal(id,data) {
        var displayModal = document.getElementById('skModal')
        var backgroundModal = document.querySelector('.modal-backdrop')
        var implementHeaderTitle = document.querySelector(".skModal .implementHeaderTitle")
        var hiddenInput = document.querySelector(".skModal .hiddenInput")
        if (displayModal) {
            displayModal.classList.add("show")
            displayModal.classList.add("open")
        }
        if (backgroundModal) {
            backgroundModal.classList.add('show')
            backgroundModal.classList.add('open')
        }
        if (implementHeaderTitle) {
            implementHeaderTitle.innerText = id
        }
        if (hiddenInput) {
            hiddenInput.value = id
        }
        if (data) {
            var updateUserInput = document.querySelectorAll('.skModal .form-group input')
            for (let i = 0; i < updateUserInput.length; i++) {
                let name = updateUserInput[i].name
                if (data[name] !== undefined) {
                    updateUserInput[i].value = data[name]
                }
            }
            var updateUserSelect = document.querySelectorAll('.skModal .form-group select')
            for (let i = 0; i < updateUserSelect.length; i++) {
                let name = updateUserSelect[i].name
                if (data[name] !== undefined) {
                    updateUserSelect[i].value = data[name]
                }
            }
        }
        return true
    }

    closeModal() {
        var container__modal = document.querySelector('.container__modal')
        var displayModal = document.getElementById('skModal')
        var modal_backdrop = document.querySelector('.modal-backdrop')
        displayModal.remove()
        modal_backdrop.classList.remove('show')
        modal_backdrop.classList.remove('open')
    }

};

