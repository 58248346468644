import HelperService from "./HelperService";
import ApiMedias from "../Api/ApiMedias";

export default class MediaService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiMedias();
        this.#helper = new HelperService();
    }


    async read(key, name) {
        await this.#api.read(key, name);
    }
}
