import React, {useEffect, useState}  from "react";
import {motion as m} from "framer-motion";
import CollapseComponent from "../../StarterKit/Components/CollapseComponent";
import {ProductCardDetail} from "../Components/ProductCardDetail";
import {IconActionButton} from "../Components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Dropdown } from 'semantic-ui-react'
import ShopService from "../Services/Bll/ShopService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import 'semantic-ui-css/semantic.min.css'
import {HeaderComponent} from "../Components/HeaderComponent";
import ModaleQrCode from "../Components/ModaleQrCode";
import 'semantic-ui-css/semantic.min.css'
import ProductService from "../Services/Bll/ProductService";
import {ProductCardShop} from "../Components/ProductCardShop";

export default function StockShop () {
    const service = new ShopService();
    const [data, setData] = useState(null);
    const [selected, setSelected] = useState('');

    useEffect(() => {
        service.list().then(function (result) {
            let res = result.getData();
            if (res.stockDetail){
                setData(res);
                setSelected(Object.keys(res.stockDetail)[0])
            }
        })
    },[])


    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16)+"000000000").substr(2,8);
            return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }
        return (
            <>
                {data &&
                <m.div className={"wrapper"}>
                    <div id={"page"}>
                        <HeaderComponent logo={'/pictures/Logo_Pequignet.png'} title={CacheExtension.getDataFromCache('Stock').replace('%%1%%', data.name)}></HeaderComponent>

                        <div id="content">
                            <div id={"page"}>
                                <div className={'filters d-flex align-items-end'}>
                                    <div>
                                        <div>
                                            <label htmlFor="">{CacheExtension.getDataFromCache('modeleAvailable')}</label>
                                            <Dropdown
                                                placeholder={CacheExtension.getDataFromCache('PlaceholderDropdownWatch')}
                                                fluid
                                                search
                                                selection
                                                options={Object.keys(data.stockDetail).map((key, i) => ({ key: i, value: key, text: key }))}
                                                onChange={(e, { value }) => setSelected(value)}
                                            />
                                        </div>

                                    </div>
                                    {/*<ModaleQrCode tooltip={CacheExtension.getDataFromCache('receiveProduct')} icon={<button className={'btn btn-primary'} >{CacheExtension.getDataFromCache('receiveProduct')}</button>}  action={function (key){*/}
                                    {/*    var productService = new ProductService()*/}
                                    {/*    productService.received(key)*/}
                                    {/*}}></ModaleQrCode>*/}

                                </div>

                                <div className="App">
                                    { selected && (
                                        <div key={selected}>
                                            <h5 className={'typeTitle'}>{selected}</h5>
                                            <div className={'modelsContainer d-flex flex-wrap'}>
                                                {Object.keys(data.stockDetail[selected]).map((variant,index) => {
                                                    return <ProductCardShop key={variant} variant={variant} data={data.stockDetail[selected][variant]}></ProductCardShop>
                                                })}
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </m.div>
                }
            </>
        );
}
