import SettingService from "../../../StarterKit/Service/Bll/SettingService";

export default class HelperService {

    async setLocalStorage() {
        var serviceSettings = new SettingService();
        if (!localStorage.getItem('locale')) {
            localStorage.setItem('locale', localStorage['locale'] ? localStorage['locale'] : 'fr');
        }
        if (!localStorage.getItem('securityZone')) {
            var securityZone = await serviceSettings.getFromGroup("securityZone");
            localStorage.setItem(securityZone.context.group, JSON.stringify(securityZone.getData()));
        }
        var resultCache = await serviceSettings.getFromGroup("cacheBack");
        var settings = resultCache.getData();
        if (Object.keys(settings).length > 0) {
            for (const settingsKey in settings) {
                if (!localStorage.getItem(settingsKey)) {
                    var resultSettings = await serviceSettings.getFromGroup(settingsKey);
                    localStorage.setItem(settingsKey, JSON.stringify(resultSettings.getData()));
                }
            }
        }
    }
}
