import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/users.css';
import {motion as m} from "framer-motion"
import { createRoot } from 'react-dom/client';
import React, {useEffect, useState,} from "react";
import { Nav } from "../../components/navComponents"
import { Header } from "../../components/headerComponent"
import Autoform from "../Autoform/Autoform"
import UsersService from "../Service/Bll/UsersService";
import Sk from "../Sk";
import CacheExtension from "../Filter/CacheExtension";
import {IconActionButton} from "../../App/Components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RightService from "../Service/Bll/RightService";

export default function Users() {
    $(function () { //todo call once
        $('[data-toggle="tooltip"]').tooltip()
    })
    const [post, setPost] = React.useState(null);
    var service = new UsersService()
    const changePasswordObject = {
        update : {
            "error":-1000,
            "password" : {
                "type":"password",
                "error":-1100,
                "mandatory":false,
                "default":false,
                "regex": "^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$"
            },
            "confirmPassword" : {
                "type":"password",
                "error":-1100,
                "mandatory":false,
                "notObligatory" : true,
                "default":false,
                "regex": "^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$"
            }
        }
    }
    React.useEffect(() => {
        fetchData()
        var editUsers = document.querySelectorAll(".editUsers")
        var oddTableLine = document.querySelectorAll('.oddTableLine')
        for (let i = 0; i < editUsers.length ; i++) {
            editUsers[i].addEventListener('click',function (e) {
                e.preventDefault()
                // oddTableLine[i].classList.add('')
            })
        }
    }, []);
    if (!post) return  null
    var valueForm = post.context['forms']

    function fetchData(search = null) {
        service.list(search ?? "").then(function (result) {
            setPost(result)
        })
    }

    function updateUser(id,data) {
        let notUsed = []
        if(data.profile == 'shop') {
            notUsed = ['password', 'firstname']
        } else if (data.profile == 'owner') {
            notUsed = ['password', 'lastname']
        } else {
            notUsed = ['password']
        }
        var container__modal = document.querySelector('.container__modal')
        var openModal = new Autoform([{ dataModal : data, idModal : id,  methodRoute : "put" ,  declaration: 'update' , route : "users" , keyName : 'update' , data : valueForm.update , modale : true , name : 'user' , classInput : 'form-control' , cancel : 'cancel' , submit : 'submit' , modaleHeader : "HeaderUpdateUser" , hidden : true, notUsed: notUsed }],UsersService)
        const root = createRoot(container__modal);
        var a = root.render(openModal.render());
        setTimeout(() => {
            openModal.openModal(id,data)
        }, "100")
    }

    function updateUserPassword(id,data) {
        let notUsed = ['firstname','lastname', 'phoneNumber', 'defaultLanguage', 'profile']
        var container__modal = document.querySelector('.container__modal')
        var openModal = new Autoform([{ dataModal : data, idModal : id,  methodRoute : "put" ,  declaration: 'update' , route : "users" , keyName : 'update' , data : valueForm.update , modale : true , name : 'user' , classInput : 'form-control' , cancel : 'cancel' , submit : 'submit' , modaleHeader : "HeaderUpdateUser" , hidden : true, notUsed: notUsed }],UsersService)
        const root = createRoot(container__modal);
        var a = root.render(openModal.render());
        setTimeout(() => {
            openModal.openModal(id,data)
        }, "100")
    }

    function UsersDisable(id) {
        const UsersDisableAsync = async event => {

            var result = await service.disable(id)
            if (result.getCode() === 0) {
                // navigate('/')
            }
            fetchData()
        }
        UsersDisableAsync();
    }

    function UsersEnable(id) {
        const UsersEnableAsync = async event => {
            var result = await service.enable(id)
            if (result.getCode() === 0) {
                // navigate('/')
            }
            fetchData()
        }
        UsersEnableAsync();

    }
    function UsersLock(id) {
        const UsersLockAsync = async event => {
            var result = await service.lock(id)
            if (result.getCode() === 0) {
                // navigate('/')
            }
            fetchData()
        }
        UsersLockAsync();
    }
    function UsersUnlock(id) {
        const UsersUnlockAsync = async event => {
            var result = await service.unlock(id)
            if (result.getCode() === 0) {
                // navigate('/')
            }
            fetchData()
        }
        UsersUnlockAsync();
    }

    function readUsersSearch(e) {
        e.preventDefault()
        var formUserGet = document.querySelector(".formSearchUser")
        var inputsFormUserGet = document.querySelectorAll('.formSearchUser input')
        const currentLanguage = sessionStorage['defaultLanguage'];
        var selectsFormUserGet = document.querySelectorAll('.formSearchUser select')
        var search = ""
        for (let i = 0; i < inputsFormUserGet.length ; i++) {
            search = search + inputsFormUserGet[i].value
        }
        for (let i = 0; i < selectsFormUserGet.length ; i++) {
            search = search + "&" + selectsFormUserGet[i].name + "=" + selectsFormUserGet[i].value
        }
        fetchData(search)
    }

    async function addUser(key,setting) {
        let res = await service.list()
        valueForm = res.context.forms
        var container__modal = document.querySelector('.container__modal')
        var autoform = new Autoform([
            {
                submitCallback: async() => {
                    autoform.closeModal();
                    // fetchData();//todo
                },
                groupsForm: "",
                langForm: "",
                declaration: 'add',
                methodRoute: "post",
                route: "users",
                keyName: 'new',
                data: valueForm.new,
                modale: true,
                name: 'user',
                classInput: 'form-control',
                cancel: CacheExtension.getDataFromCache('cancel'),
                submit: CacheExtension.getDataFromCache('submit'),
                modaleHeader: CacheExtension.getDataFromCache("HeaderAddUser"),
                hidden: true
            }
        ], UsersService);
        const root = createRoot(container__modal);
        var a = root.render(autoform.render());
        setTimeout(() => {
            autoform.openModal(key,setting)
        }, "100")
    }
    { new Autoform([{submitCallback: "", declaration: 'add' , methodRoute:"post", route:"/users",  keyName:'new', data: valueForm.new, modale:true, name:'user', classInput:'form-control'  }],UsersService ).render() }

    return (
        <>
            <m.div className={"wrapper"}>
                <Nav />
                <div id={"page"}>
                    <Header />

                    <div id="content">
                        <div className="card">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h3>{CacheExtension.getDataFromCache('UsersPageTitle')}</h3>
                                        <input type="hidden" defaultValue={1} id="users_page"/>
                                        <input type="hidden" defaultValue={1} id="users_pageCount"/>
                                    </div>
                                    <div className="col-6 text-right">
                                        <a
                                            className="actionLink mr-3"
                                            onClick={() => Sk.skExportCSV('Utilisateurs','Utilisateurs')}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={"ExportCSV"}
                                            data-original-title="Exporter la table en CSV">
                                            <svg
                                                className="svg-inline--fa fa-download"
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="download"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                data-fa-i2svg="">
                                                <path
                                                    fill="currentColor"
                                                    d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={"card-body container fullSize"}>

                                <div className="row mt-2 pb-2">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <strong data-csvheader="text">Email</strong>
                                            </div>
                                            <div className="col-3">
                                                <strong data-csvheader="text">Prénom</strong>
                                            </div>
                                            <div className="col-4">
                                                <strong data-csvheader="text">Nom</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 text-center">
                                        <strong data-csvheader="text">Numéro de téléphone</strong>
                                    </div>
                                    <div className="col-3 text-center">
                                        <div className="row">
                                            <div className="col-6 text-center">
                                                <strong data-csvheader="text">Profil</strong>
                                            </div>
                                            <div className="col-6 text-center">
                                                <strong data-csvheader="text">Langue</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <input type="hidden" data-csvheader="val" defaultValue="Actif ?"/>
                                        <input type="hidden" data-csvheader="val" defaultValue="Bloqué ?"/>
                                        <input
                                            type="hidden"
                                            data-csvheader="val"
                                            defaultValue="Mot de passe expiré ?"
                                        />
                                    </div>
                                </div>
                                <form className="row border-bottom mt-1 formSearchUser" onSubmit={(e) => readUsersSearch(e)}>
                                    <div className="col-4">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                data-sk="filter"
                                                data-sktype="text"
                                                data-skname="name"
                                                placeholder="Recherche sur email, nom ou prénom"
                                                data-csvfilter="Recherche sur email, nom ou prénom"
                                                className="form-control"
                                                id="users_search"
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    data-sk="clear"
                                                    data-sktype="text"
                                                    data-skname="users_search"
                                                    data-skaction="UsersSearch"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title=""
                                                    data-original-title="Effacer le filtre"
                                                >
                                                    <svg
                                                        className="svg-inline--fa fa-xmark"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fas"
                                                        data-icon="xmark"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 320 512"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                        />
                                                    </svg>
                                                    {/* <i class="fas fa-times"></i> Font Awesome fontawesome.com */}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 text-center">
                                        <div className="input-group mb-3">
                                            <select
                                                className="custom-select"
                                                id="users_search_state"
                                                data-sk="filter"
                                                data-sktype="select"
                                                name={'state'}
                                                data-skname="state"
                                                onChange={(e) => readUsersSearch(e)}
                                            >
                                                <option value="">Tous les comptes</option>
                                                <option value="locked">Comptes bloqués</option>
                                                <option value="unlocked">Comptes débloqués</option>
                                                <option value="inactive">Comptes désactivés</option>
                                                <option value="unused">Comptes inutilisés</option>
                                                <option value="expired">Mot de passes expirés</option>
                                            </select>
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    data-sk="clear"
                                                    data-sktype="select"
                                                    data-skname="users_search_state"
                                                    data-skaction="UsersSearch"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title=""
                                                    data-original-title="Effacer le filtre">
                                                    <svg className="svg-inline--fa fa-xmark"
                                                         aria-hidden="true"
                                                         focusable="false"
                                                         data-prefix="fas"
                                                         data-icon="xmark"
                                                         role="img"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 320 512"
                                                         data-fa-i2svg="">
                                                        <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        {" "}
                                    </div>
                                    <div className="col-2">&nbsp;</div>
                                    <div className="col-3 text-center">
                                        <div className="row">
                                            <div className="col-6 text-center">
                                                <div className="input-group mb-3">
                                                    <select
                                                        className="custom-select"
                                                        id="users_search_profile"
                                                        data-sk="filter"
                                                        data-sktype="select"
                                                        name={'profile'}
                                                        data-skname="profile"
                                                        onChange={(e) => readUsersSearch(e)}
                                                    >
                                                        <option value="">Tous les profils</option>
                                                        <option value="anonymous">Anonyme</option>
                                                        <option value="service">Compte de service</option>
                                                        <option value="admin">Super administrateur</option>
                                                        <option value="guest">Utilisateur enregistré</option>
                                                    </select>
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            type="button"
                                                            data-sk="clear"
                                                            data-sktype="select"
                                                            data-skname="users_search_profile"
                                                            data-skaction="UsersSearch"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title=""
                                                            data-original-title="Effacer le filtre"
                                                        >
                                                            <svg
                                                                className="svg-inline--fa fa-xmark"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="xmark"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 320 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                {" "}
                                            </div>
                                            <div className="col-6 text-center">
                                                <div className="input-group mb-3">
                                                    <select
                                                        className="custom-select"
                                                        id="users_search_language"
                                                        data-sk="filter"
                                                        data-sktype="select"
                                                        name={'language'}
                                                        data-skname="language"
                                                        onChange={(e) => readUsersSearch(e)}
                                                    >
                                                        <option value="">Toutes les langues</option>
                                                        <option value="EN">Anglais</option>
                                                        <option value="FR">Français</option>
                                                    </select>
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            type="button"
                                                            data-sk="clear"
                                                            data-sktype="select"
                                                            data-skname="users_search_language"
                                                            data-skaction="UsersSearch"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title=""
                                                            data-original-title="Effacer le filtre">
                                                            <svg
                                                                className="svg-inline--fa fa-xmark"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="xmark"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 320 512"
                                                                data-fa-i2svg="">
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                {" "}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 text-center">&nbsp;</div>
                                </form>
                                    <IconActionButton tooltip={CacheExtension.getDataFromCache('RightsAddProfileTooltip')} action={() => addUser()} state={true} icon={<FontAwesomeIcon icon="fas fa-plus-square"/>}/>
                                { (post) ? (
                                    post.data.map( (data, index) =>
                                        <form key={index} action="">
                                            <div
                                                className={"row pt-2 pb-2 align-items-center" +(index%2 ? ' evenTableLine':' oddTableLine')  }
                                                id={'user_' + data.id }
                                                data-id={1000}
                                                data-index={1}
                                                data-background="oddTableLine">
                                                <div className="col-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-5">
                                                                <span
                                                                    className="mr-1 container__information__users"
                                                                    data-toggle="tooltip"
                                                                    data-placement="left"
                                                                    title=""
                                                                    data-original-title={"last-login " + data.lastLoginOn + "create-user-account " + data.createdOn}>
                                                                    <svg
                                                                        className="svg-inline--fa fa-circle-info 03/05/2023"
                                                                        aria-hidden="true"
                                                                        focusable="false"
                                                                        data-prefix="fas"
                                                                        data-icon="circle-info"
                                                                        role="img"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 512 512"
                                                                        data-fa-i2svg="">
                                                                        <path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                                                                    </svg>
                                                                    <div className={'tooltip fade bs-tooltip-right'}>
                                                                        <div className={'arrow'}></div>
                                                                        <div className={'tooltip-inner'}>
                                                                            <p>
                                                                                {"last-login"} {":"} {convertdate(data.lastLoginOn)} </p>
                                                                            <p>
                                                                                {"create-user-account"} {':'} {convertdate(data.createdOn)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            <strong className=" 03/05/2023" data-csvdata="text">
                                                                {data.email}
                                                            </strong>
                                                        </div>
                                                        <div className="col-3">
                                                                <span id="user_data_firstname_100" itemID={'user_data_firstname_'+ data.id} data-csvdata="text">
                                                                    {data.firstname}
                                                                </span>
                                                            <input
                                                                type="text"
                                                                defaultValue="Admin"
                                                                placeholder="Admin"
                                                                className="hidden form-control"
                                                                id={"user_edit_firstname_"+data.id}
                                                                itemID={'user_edit_firstname_'+ data.id}
                                                                style={{
                                                                    backgroundImage: 'url("data:image/png',
                                                                    backgroundRepeat: "no-repeat",
                                                                    backgroundAttachment: "scroll",
                                                                    backgroundSize: "16px 18px",
                                                                    backgroundPosition: "98% 50%"
                                                                }}
                                                            />
                                                            <div className="invalid-feedback">Le prénom est obligatoire</div>
                                                        </div>
                                                        <div className="col-4">
                                                                <span id={"user_data_lastname_"+data.id} data-csvdata="text">
                                                                    {data.lastname}
                                                                </span>
                                                            <input
                                                                type="text"
                                                                defaultValue="Diji"
                                                                placeholder="Diji"
                                                                className="hidden form-control"
                                                                id={"user_edit_lastname_" + data.id}
                                                                style={{
                                                                    backgroundImage: 'url("data:image/png',
                                                                    backgroundRepeat: "no-repeat",
                                                                    backgroundAttachment: "scroll",
                                                                    backgroundSize: "16px 18px",
                                                                    backgroundPosition: "98% 50%"
                                                                }}
                                                            />
                                                            <div className="invalid-feedback">Le nom est obligatoire</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 text-center">
                                                    <span id={"user_data_phonenumber_" + data.id} data-csvdata="text"/>
                                                    <input
                                                        type="text"
                                                        defaultValue=""
                                                        placeholder=""
                                                        className="hidden form-control"
                                                        id={"user_edit_phonenumber_" + data.id}
                                                        style={{
                                                            backgroundImage: 'url("data:image/png',
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundAttachment: "scroll",
                                                            backgroundSize: "16px 18px",
                                                            backgroundPosition: "98% 50%"
                                                        }}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Le numéro de téléphone est obligatoire
                                                    </div>
                                                </div>
                                                <div className="col-3 text-center">
                                                    <div className="row align-items-center">
                                                        <div className="col-6 text-center">
                                                                <span id={"user_data_profile_" + data.id} data-csvdata="text">
                                                                    {data.profile}
                                                                </span>
                                                            <select defaultValue="admin" className="hidden custom-select" id={"user_edit_profile_" + data.id}>
                                                                <option value="anonymous">Anonyme</option>
                                                                <option value="service">Compte de service</option>
                                                                <option value="admin">
                                                                    Super administrateur
                                                                </option>
                                                                <option value="guest">Utilisateur enregistré</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-6 text-center">
                                                                <span id={"user_data_language_" + data.id} data-csvdata="text">
                                                                    Français
                                                                </span>
                                                            <select defaultValue="FR" className="hidden custom-select" id={"user_edit_language_" + data.id}>
                                                                <option value="EN">Anglais</option>
                                                                <option value="FR">
                                                                    Français
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div >
                                                    <div >
                                                        <div className={"containerButtonUsers" + data.id }>
                                                            <input type="hidden" data-csvdata="val" defaultValue="X"/>
                                                            <input type="hidden" data-csvdata="val" defaultValue=""/>
                                                            <input type="hidden" data-csvdata="val" defaultValue=""/>
                                                            <a
                                                                onClick={() => updateUser(data.id,data)}
                                                                id={"user_edit_" + data.id}
                                                                className="actionLink mr-1 editUsers"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={CacheExtension.getDataFromCache("UsersUpdate")}
                                                                data-original-title="Modifier ce compte">
                                                                <svg
                                                                    className="svg-inline--fa fa-pencil"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="pencil"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512"
                                                                    data-fa-i2svg="">
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z"
                                                                    />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                className="actionLink mr-2"
                                                                id={"user_password_" + data.id}
                                                                onClick={() => updateUserPassword(data.id,data)}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={CacheExtension.getDataFromCache("UsersChangePassword")}
                                                                data-original-title="Changer le mot de passe">
                                                                <svg
                                                                    className="svg-inline--fa fa-lock"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="lock"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 448 512"
                                                                    data-fa-i2svg="">
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
                                                                    />
                                                                </svg>
                                                            </a>
                                                            <a
                                                                id={"user_save_" + data.id}
                                                                className="hidden actionLink mr-1"
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={CacheExtension.getDataFromCache("UsersSave")}
                                                                data-original-title="Enregistrer les modifications">
                                                                <svg
                                                                    className="svg-inline--fa fa-floppy-disk"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="floppy-disk"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 448 512"
                                                                    data-fa-i2svg="">
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 416c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"
                                                                    />
                                                                </svg>
                                                            </a>
                                                            <a id={"user_cancel_" + data.id}
                                                               className="hidden actionLink mr-2"
                                                               data-toggle="tooltip"
                                                               data-placement="top"
                                                               title={CacheExtension.getDataFromCache("UsersCancel")}
                                                               data-original-title="Annuler les modifications">
                                                                <svg
                                                                    className="svg-inline--fa fa-xmark"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="xmark"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 320 512"
                                                                    data-fa-i2svg="">
                                                                    <path fill="currentColor"
                                                                          d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                                                                </svg>
                                                            </a>
                                                            <br/>
                                                            <a
                                                                className="actionLink buttonUserEnable mr-1 mt-1"
                                                                id={"user_enabled_" + data.id}
                                                                data-toggle="tooltip"
                                                                onClick={() => data.enabled ? UsersDisable(data.id) : UsersEnable(data.id)}
                                                                data-placement="top"
                                                                title={CacheExtension.getDataFromCache("UsersEnable")}
                                                                data-original-title="Compte actif">
                                                                {data.enabled ?

                                                                    <svg
                                                                        className="svg-inline--fa fa-user-check"
                                                                        aria-hidden="true"
                                                                        focusable="false"
                                                                        data-prefix="fas"
                                                                        data-icon="user-check"
                                                                        role="img"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 640 512"
                                                                        data-fa-i2svg="">
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M352 128c0 70.7-57.3 128-128 128s-128-57.3-128-128S153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                                                    </svg>:
                                                                    <svg className="svg-inline--fa fa-user-slash text-danger"
                                                                         aria-hidden="true"
                                                                         focusable="false"
                                                                         data-prefix="fas"
                                                                         data-icon="user-slash"
                                                                         role="img"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 640 512"
                                                                         data-fa-i2svg="">
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"
                                                                        />
                                                                    </svg>
                                                                }
                                                            </a>
                                                            {/*<a className=" buttonUserDisable actionLink mr-1 mt-1"*/}
                                                            {/*   id={"user_disabled_" + data.id}*/}
                                                            {/*   onClick={() => UsersDisable(data.id)}*/}
                                                            {/*   data-toggle="tooltip"*/}
                                                            {/*   data-placement="top"*/}
                                                            {/*   title={CacheExtension.getDataFromCache("UsersDisable")}*/}
                                                            {/*   data-original-title="Compte désactivé">*/}
                                                            {/*    <svg className="svg-inline--fa fa-user-slash text-danger"*/}
                                                            {/*         aria-hidden="true"*/}
                                                            {/*         focusable="false"*/}
                                                            {/*         data-prefix="fas"*/}
                                                            {/*         data-icon="user-slash"*/}
                                                            {/*         role="img"*/}
                                                            {/*         xmlns="http://www.w3.org/2000/svg"*/}
                                                            {/*         viewBox="0 0 640 512"*/}
                                                            {/*         data-fa-i2svg="">*/}
                                                            {/*        <path*/}
                                                            {/*            fill="currentColor"*/}
                                                            {/*            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"*/}
                                                            {/*        />*/}
                                                            {/*    </svg>*/}
                                                            {/*</a>*/}
                                                            <a className=" compteLock  actionLink mr-1 mt-1"
                                                               id={"user_locked_" + data.id}
                                                               data-toggle="tooltip"
                                                               onClick={() => data.locked ? UsersUnlock(data.id) : UsersLock(data.id)}
                                                               data-placement="top"
                                                               title={CacheExtension.getDataFromCache("UsersLock")}
                                                               data-original-title="Compte verrouillé">
                                                                {data.locked ?
                                                                    <svg
                                                                        className="svg-inline--fa fa-user-lock text-danger"
                                                                        aria-hidden="true"
                                                                        focusable="false"
                                                                        data-prefix="fas"
                                                                        data-icon="user-lock"
                                                                        role="img"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 640 512"
                                                                        data-fa-i2svg="">
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H392.6c-5.4-9.4-8.6-20.3-8.6-32V352c0-2.1 .1-4.2 .3-6.3c-31-26-71-41.7-114.6-41.7H178.3zM528 240c17.7 0 32 14.3 32 32v48H496V272c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32V272c0-44.2-35.8-80-80-80s-80 35.8-80 80z"
                                                                        />
                                                                    </svg>:
                                                                    <svg
                                                                        className="svg-inline--fa fa-user-lock color-inactive"
                                                                        aria-hidden="true"
                                                                        focusable="false"
                                                                        data-prefix="fas"
                                                                        data-icon="user-lock"
                                                                        role="img"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 640 512"
                                                                        data-fa-i2svg="">
                                                                        <path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H392.6c-5.4-9.4-8.6-20.3-8.6-32V352c0-2.1 .1-4.2 .3-6.3c-31-26-71-41.7-114.6-41.7H178.3zM528 240c17.7 0 32 14.3 32 32v48H496V272c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32V272c0-44.2-35.8-80-80-80s-80 35.8-80 80z"/>
                                                                    </svg>
                                                                }

                                                            </a>
                                                            {/*<a className="actionLink compteUnlock mr-1 mt-1"*/}
                                                            {/*   id={"user_unlocked_" + data.id}*/}
                                                            {/*   data-toggle="tooltip"*/}
                                                            {/*   onClick={() => UsersUnlock(data.id)}*/}
                                                            {/*   data-placement="top"*/}
                                                            {/*   title={CacheExtension.getDataFromCache("UsersUnlock")}*/}
                                                            {/*   data-original-title="Compte non verrouillé">*/}
                                                            {/*    <svg*/}
                                                            {/*        className="svg-inline--fa fa-user-lock color-inactive"*/}
                                                            {/*        aria-hidden="true"*/}
                                                            {/*        focusable="false"*/}
                                                            {/*        data-prefix="fas"*/}
                                                            {/*        data-icon="user-lock"*/}
                                                            {/*        role="img"*/}
                                                            {/*        xmlns="http://www.w3.org/2000/svg"*/}
                                                            {/*        viewBox="0 0 640 512"*/}
                                                            {/*        data-fa-i2svg="">*/}
                                                            {/*        <path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H392.6c-5.4-9.4-8.6-20.3-8.6-32V352c0-2.1 .1-4.2 .3-6.3c-31-26-71-41.7-114.6-41.7H178.3zM528 240c17.7 0 32 14.3 32 32v48H496V272c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32V272c0-44.2-35.8-80-80-80s-80 35.8-80 80z"/>*/}
                                                            {/*    </svg>*/}
                                                            {/*</a>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                ) : ( 'ok'
                                )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </m.div>
        </>
    );
}

var a = document.querySelectorAll("[id*=skModal]")
function convertdate(date) {
    if (date != null) {
        date = date.split('T')[0]
        var language = sessionStorage['language']
        var mydate = new Date(date);
        var finaldate = mydate.toLocaleDateString(language)
        return finaldate
    } else {
        return " "
    }

}



