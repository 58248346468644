import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import '../css/bo.css'
import {useEffect, useState} from "react";
import ProductService from "../Services/Bll/ProductService";


export default function InTransitModalComponent () {

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    const service = new ProductService()
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        service.listState('inTransit').then(function (result) {
            let res = result.getData();
            setData(res)
        })
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            {data ?
                <button disabled={!data.products || data.products.length === 0} className="btn btnBo" onClick={handleOpen}>
                    {CacheExtension.getDataFromCache('InTransit')}
                </button>
            : ('')}
            {open && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-75" style={{maxWidth: "none"}}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{CacheExtension.getDataFromCache('InTransit')}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="row mx-0 py-2" style={{borderBottom: '1px solid #dee2e6'}}>
                                <div className="col-4">
                                    <strong>{CacheExtension.getDataFromCache('Serial')}</strong>
                                </div>
                                <div className="col-4">
                                    <strong>{CacheExtension.getDataFromCache('SendingType')}</strong>
                                </div>
                                <div className="col-4">
                                    <strong>{CacheExtension.getDataFromCache('Destination')}</strong>
                                </div>
                            </div>
                                <div className="fullSize modal-body p-0">

                                    {data &&
                                        data.products.map((product, i) => (
                                            <div className={'row mx-0 py-2 ' + (i % 2 === 0 ? 'oddTableLine' : 'evenTableLine')}>
                                                <div className="col-4">
                                                    <p>{product.serial}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>{product.shopKey ? CacheExtension.getDataFromCache('Shop') : CacheExtension.getDataFromCache('Other')}</p>
                                                </div>
                                                {product.destination &&
                                                    <div className="col-4">
                                                        <p className="m-0">{product.destination.address}</p>
                                                        <p className="m-0">{product.destination.zipCode + ' ' + product.destination.city}</p>
                                                        <p className="m-0">{product.destination.country}</p>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
