import CacheExtension from "../../Filter/CacheExtension";

class SelectAutoform{
    #state
    constructor(parameter) {
        this.#state = {
            dataSelect : JSON.parse(localStorage[parameter.parameterObject.group]),
            modale : parameter.modale,
            parameter : parameter,
        };

        if (this.#state.keyform) {
            this.#state.mandatory = true
        }
    }

    check() {
        var checkValue = true
        var returnTable = []
        var errorCode = ""
        var arrayInput = {}
        var className = "." +  this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData
        var input = document.querySelector(className)
        if (input.value === "" &&  this.#state.mandatory === true) {
            checkValue = false
        }
        if (input.value === "" &&  this.#state.mandatory === true && this.parameter.parameterObject.group == "profile") {
            errorCode = -1074
        }
        returnTable.push(checkValue,input.name,input.value,errorCode)
        return returnTable
    }

    render() {
        var keyObjectForm = Object.keys(this.#state.dataSelect);
        var valueObjectForm = Object.values(this.#state.dataSelect);
        return(
            <div key={Math.random()} className={`banner ${ this.#state.modale == true ? "form-group" : ""}`}>
                <label>{CacheExtension.getDataFromCache('label-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData)}</label>
                <select  placeholder={CacheExtension.getDataFromCache('placeholder-' + this.#state.parameter.parameterData + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData)}  name={this.#state.parameter.parameterData} className={this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData + " form-control"} type="select">
                    {
                        keyObjectForm.map( (x,y) =>
                            <option value={keyObjectForm[y]} key={x}>{valueObjectForm[y].text}</option>)
                    }
                </select>
            </div>
        )
    }
}

export default SelectAutoform