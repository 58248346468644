import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import ProductService from "../Services/Bll/ProductService";
import {Dropdown} from 'semantic-ui-react';

export const ProductShopModal = (props) => {
    const initialForm = {
        // mail: ''

    };


    const [data, setData] = useState(null);
    var service = new ProductService()
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [selected, setSelected] = useState("");



    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    useEffect(() => {
        service.listFromModel(props.model).then(function (result) {
            let res = result.getData();
            setData(res)
        })
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    return (
        <>
            {data ?
                <button disabled={data.length === 0} className="btn btnBo" onClick={handleOpen}>
                    {CacheExtension.getDataFromCache('Sell')}
                </button>
                : ('')}
            {open && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-75" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">{CacheExtension.getDataFromCache('ProductList')}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="fullSize modal-body">
                                {data &&
                                    <>
                                        <div className={'form-group'}>
                                            <p className={'mb-1'}>{CacheExtension.getDataFromCache("Serial")}</p>
                                            <Dropdown
                                                style={{width: '200px'}}
                                                placeholder={CacheExtension.getDataFromCache('SelectSerial')}
                                                fluid
                                                search
                                                selection
                                                onChange={(e, {value}) => {
                                                    setSelected(value)
                                                }}
                                                options={data.map((product, i) =>({
                                                    key: i,
                                                    value: product.key,
                                                    text: product.serial
                                                }))}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="name">{CacheExtension.getDataFromCache('Firstname')}</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="name"
                                                           onChange={handleChange}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="lastname">{CacheExtension.getDataFromCache('Lastname')}</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="lastname"
                                                           onChange={handleChange}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="title">{CacheExtension.getDataFromCache('PersonTitle')}</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="title"
                                                           onChange={handleChange}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="email">{CacheExtension.getDataFromCache('Email')}</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="email"
                                                           onChange={handleChange}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="phone">{CacheExtension.getDataFromCache('Phone')}</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="phone"
                                                           onChange={handleChange}/>
                                                </div>
                                                <div className="form-group">
                                                    <label>{CacheExtension.getDataFromCache('DefaultLanguage')}</label>
                                                    <Dropdown
                                                        defaultValue={'EN'}
                                                        fluid
                                                        selection
                                                        onChange={async (e, {value}) => {
                                                            setForm({
                                                                ...form,
                                                                defaultLanguage: value
                                                            })
                                                        }}
                                                        options={[{value : 'FR', text: 'FR'}, {value : 'EN', text: 'EN'}]}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="date">{CacheExtension.getDataFromCache('SaleDate')}</label>
                                                    <input className="form-control" type='date' name="date" onChange={handleChange} />
                                                </div>


                                                <div className="d-flex justify-content-end">
                                                    <button className="btn btnBo" disabled={!form.name || !form.lastname || !form.email || !form.defaultLanguage || !selected} onClick={ async () => {
                                                        await service.sellAndCreate(selected, form);
                                                        window.location.reload();
                                                    }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

ProductShopModal.propTypes = {
    model : PropTypes.string.isRequired,
    icon : PropTypes.element.isRequired,
}
