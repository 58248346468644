import React from "react";
import PropTypes from "prop-types";
import '../css/ProductCardDetail.css';
import '../css/bo.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProductService from "../Services/Bll/ProductService";
import ModaleQrCode from "./ModaleQrCode";
import {ProductListModal} from "./ProductListModal";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import { Dropdown } from 'semantic-ui-react'
import {ProductShopModal} from "./ProductShopModal";



export const ProductCardShop = (props) => {
    var productService = new ProductService()

    function customInputs() {
        return (
            [
                <div id={'firstName'}>
                    <label className={'mb-1'}>{CacheExtension.getDataFromCache("firstName")}</label>
                    <input type="text" style={{width : '200px'}} className="form-control"/>
                </div>,
                <div id={'lastName'}>
                    <label className={'mb-1'}>{CacheExtension.getDataFromCache("lastName")}</label>
                    <input type="text" style={{width : '200px'}} className="form-control"/>
                </div>,
                <div id={'email'}>
                    <label className={'mb-1'}>{CacheExtension.getDataFromCache("email")}</label>
                    <input type="text" style={{width : '200px'}} className="form-control"/>
                </div>,
                <div id={'language'}>
                    <label className={'mb-1'}>{CacheExtension.getDataFromCache('language')}</label>
                    <Dropdown
                        style={{width : '200px'}}
                        placeholder={CacheExtension.getDataFromCache('PlaceholderDropdownWatch')}
                        fluid
                        search
                        selection
                        options={[{ key: "FR", value: "FR", text: "FR" }, { key: "EN", value: "EN", text: "EN" }]}
                        onChange={(e, { value }) => $('#language').data('value', value)}
                    />
                </div>
            ]
        )
    }

    return (
        <>
            <div data-variant={props.variant ?? ''} data-key={props.data.key} className={'p-2'} style={{minWidth : '300px',flex: '0 0 25%'}}>
                <div className="productCardShop">
                    <div className="productCardTitle p-2" style={{minHeight:'70px'}}>
                        <div>{props.variant}</div>
                        <div>{props.data.subtitle}</div>
                    </div>
                    <div className={'productCardContent mb-1'}>
                        <div className={'productCardPicture'} style={{backgroundImage : `url(${props.data.image})`,mixBlendMode:'multiply' }}></div>
                        {(props.data.stock !== undefined) &&
                            <>
                                <div className="d-flex align-items-center p-2 w-100 justify-content-between">
                                    <span className="mr-2 mb-2"> stock : {props.data.stock}</span>
                                    {props.data.stock !== 0 && (
                                        <ProductShopModal icon={<FontAwesomeIcon icon="fa-solid fa-list" />} model={props.data.key}></ProductShopModal>
                                    )}
                                </div>
                            </>
                        }
                    </div>
                </div>

            </div>
        </>
    );
}

ProductCardShop.propTypes = {
    data : PropTypes.object.isRequired,
    variant : PropTypes.string,
    customButtons : PropTypes.array,
    buttons : PropTypes.bool,
}
