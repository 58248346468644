import Constants from "../../Constants";
import SettingService from "./SettingService";

export default class HelperService {
    getToken() {
        var token = "";
        if (sessionStorage['token']) {
            token = sessionStorage['token'];
        }
        return token;
    }

    setToken(token) {
        sessionStorage['token'] = token
    }

    getRefreshToken() {
        var token = "";
        if (sessionStorage['refreshToken']) {
            token = sessionStorage['refreshToken'];
        }
        return token;
    }

    setRefreshToken(token) {
        sessionStorage['refreshToken'] = token
    }

    getLanguage(){
        if (sessionStorage['isLogged']){
            return sessionStorage['language']
        }else{
            return 'FR'

        }
    }

    getTextSetting(group, key) {
        var groupCache = localStorage[group] ? JSON.parse(localStorage[group]) : null;
        if (groupCache && groupCache[key] !== undefined) {
            return groupCache[key].text;
        } else {
            return "";
        }
    }

    logUser(firstname, lastname, profile, language, token, refreshToken, extra, isLogged = true) {
        sessionStorage['firstname'] = firstname;
        sessionStorage['lastname'] = lastname;
        sessionStorage['username'] = firstname +" "+ lastname;
        sessionStorage['profile'] = profile;
        sessionStorage['language'] = language;
        sessionStorage['token'] = token;
        sessionStorage['refreshToken'] = refreshToken;
        sessionStorage['lastLoginTime'] = Date.now();
        sessionStorage['extra'] = extra;
        sessionStorage['agency'] = "";
        //TODO
        // sessionStorage['vMajor'] = this.getNumericSetting("", Constants.GROUP_APP, Constants.APP_MAJOR);
        // sessionStorage['vMinor'] = this.getNumericSetting("", Constants.GROUP_APP, Constants.APP_MINOR)
        // sessionStorage['vRevision'] = this.getNumericSetting("", Constants.GROUP_APP, Constants.APP_REVISION);
        sessionStorage['isSessionInitialized'] = 'yes';

        if (!sessionStorage['isTimezoneSet'])
        {
            sessionStorage['timezone'] = 0;
            sessionStorage['isTimezoneSet'] = false;
        }

        if (isLogged) {
            sessionStorage['isLogged'] = 'yes';
        } else {
            sessionStorage['isLogged'] = 'no';
        }
    }

    logoutUser(){
        this.logUser('Anonymous',
            'Anonymous',
            'anonymous',
            'FR',
            '',
            '',
            [],
            false)
    }
}
