import React, {useEffect, useState} from "react";
import ModelService from "../Services/Bll/ModelService";
import {motion as m} from "framer-motion";
import {ProductCardDetail} from "../Components/ProductCardDetail";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import {createRoot} from 'react-dom/client';
import {Dropdown} from 'semantic-ui-react';
import {HeaderComponent} from "../Components/HeaderComponent";
import Autoform from "../../StarterKit/Autoform/Autoform";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import ShopService from "../Services/Bll/ShopService";
import ProductService from "../Services/Bll/ProductService";
import {ConfirmModalComponent} from "../../StarterKit/Components/ConfirmModalComponent";
import InTransitModalComponent from "../Components/InTransitModalComponent";


export default function StockGestion() {
    const initialForm = {
        address: '',
        city: '',
        zipCode: '',
        country: '',
        navision: '',
        comment: '',
        defaultLanguage: 'EN'
    };

    const settingService = new SettingService();
    const shopService = new ShopService();
    const productService = new ProductService();
    const service = new ModelService();
    const [data, setData] = useState(null);
    const [shopsList, setShopsList] = useState(null);
    const [post, setPost] = useState(null);
    const [context, setContext] = useState('');

    const [models, setModels] = useState(null);

    const [SKU, setSKU] = useState('');
    const [serial, setSerial] = useState('');
    const [selected, setSelected] = useState('');
    const [shop, setShop] = useState('');
    const [alreadyLinked, setAlreadyLinked] = useState('');
    const [linked, setLinked] = useState(false);
    const [sendingType, setSendingType] = useState('shop');
    const [form, setForm] = useState(initialForm);
    const [address, setAddress] = useState(false);

    useEffect(() => {

        service.listSkus().then(function (result) {
            let res = result.getData();
            setData(res)
        })

        settingService.getFromGroup('productType').then(function (result) {
            setPost(Object.keys(result.getData()))
        })

        shopService.listAll().then(function (result) {
            var res = {}
            for (const country in result.getData()) {
                for (const state in result.getData()[country]) {
                    result.getData()[country][state].forEach(function (e) {
                        res[e['key']] = e['name']
                    })
                }

            }
            res = Object.fromEntries(Object.entries(res).sort((a, b) => a[1].localeCompare(b[1])));
            setShopsList(res)
        })
    }, [])

    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    if (context) {
        var forms = context.context.forms
    }


    const handleChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }


    function modalHtml(sku, serial, shop = null) {
        return (
            <div>
                <div><strong>{CacheExtension.getDataFromCache("SKU")}</strong>{` ${sku}`}</div>
                <div><strong>{CacheExtension.getDataFromCache("Serial")}</strong> {` ${serial}`}</div>
                {shop ?
                    <div><strong>{CacheExtension.getDataFromCache("Shop")}</strong> {` ${shop}`}</div> :
                    <>
                        <div><strong>{CacheExtension.getDataFromCache("Destination")}</strong></div>
                        <p className="m-0">{form.address}</p>
                        <p className="m-0">{form.zipCode + ' ' + form.city}</p>
                        <p className="m-0">{form.country}</p>
                    </>
                }
            </div>
        )
    }

    return (
        <>
            {data && shopsList &&
                <m.div className={"wrapper"}>

                    <div id={"page"}>
                        <HeaderComponent logo={'/pictures/Logo_Pequignet.png'}
                                         title={CacheExtension.getDataFromCache('Stock').replace('%%1%%', sessionStorage['lastname'])}></HeaderComponent>
                        <div id="content">
                            <div id={"page"}>
                                <div className="d-flex justify-content-end">
                                    <InTransitModalComponent/>
                                </div>
                                <div className={'stockSection'}>
                                    <span
                                        className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%', CacheExtension.getDataFromCache('stepGestion1'))}</span>
                                    <div className={'stockSectionContent justify-content-start'}>
                                        {Object.keys(data).length > 0 ?
                                            <div className={'filters w-100 d-flex align-items-center'}>
                                                <div className={'form-group col-2 mr-1'}>
                                                    <p className={'mb-1'}>{CacheExtension.getDataFromCache("SKU")}</p>
                                                    <Dropdown
                                                        placeholder={CacheExtension.getDataFromCache('SelectSKU')}
                                                        fluid
                                                        search
                                                        selection
                                                        onChange={async (e, {value}) => {
                                                            const selected = Object.entries(data).find(entry => entry[0] === value);
                                                            setSKU(selected)
                                                            const res = await service.listFromSku(encodeURIComponent(selected[0]))
                                                            setModels(res.getData())
                                                            setSerial('');
                                                            setSelected('');
                                                            setShop('');
                                                            setAlreadyLinked('')
                                                            setLinked(false)
                                                            setAddress(false)
                                                            setForm(initialForm)
                                                            $('.ProductCardContainer').removeClass('selectedModel')
                                                        }}
                                                        options={Object.entries(data).map((value, i) => ({
                                                            key: i,
                                                            value: value[0],
                                                            text: value[0]
                                                        }))}
                                                    />
                                                </div>
                                                <div className={'form-group mr-3'}>
                                                    <p className={'mb-1'}>{CacheExtension.getDataFromCache("Serial")}</p>
                                                    <Dropdown
                                                        style={{width: '200px'}}
                                                        placeholder={CacheExtension.getDataFromCache('SelectSerial')}
                                                        fluid
                                                        search
                                                        selection
                                                        disabled={!SKU[1] || Object.entries(SKU[1]).length === 0}
                                                        onChange={(e, {value}) => {
                                                            const selected = Object.entries(SKU[1].serials).find(entry => entry[0] === value);
                                                            setSerial(selected);
                                                            setAlreadyLinked('')
                                                            setLinked(false)
                                                            if(selected[1].modelKey) {
                                                                setAlreadyLinked(models.find(item => item.key === selected[1].modelKey))
                                                                setLinked(true)
                                                            }
                                                            setSelected('');
                                                            setShop('');
                                                            setAddress(false)
                                                            setForm(initialForm)
                                                            $('.ProductCardContainer').removeClass('selectedModel')
                                                        }}
                                                        options={SKU[1] && Object.entries(SKU[1].serials).length > 0 ? Object.entries(SKU[1].serials).map((value, i) => ({
                                                            key: i,
                                                            value: value[0],
                                                            text: value[0]
                                                        })) : []}
                                                    />
                                                </div>
                                            </div> :
                                            <div className="my-2">{CacheExtension.getDataFromCache('NoProducts')}</div>
                                        }
                                    </div>
                                </div>

                                <div className={SKU ? 'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepGestion2'))}</span>
                                    <div className={'my-2 container-fluid justify-content-center'}>
                                        {models && SKU &&
                                         <>
                                             <div className={'justify-content-center row text-center mb-2'}>
                                                 {alreadyLinked ?
                                                     <div style={{maxWidth:"none"}} key={alreadyLinked.key} className={'selectedModel col-3'}>
                                                         <ProductCardDetail key={alreadyLinked.key} sku={SKU[0]} data={alreadyLinked} buttons={true}></ProductCardDetail>
                                                     </div> :
                                                     <>
                                                         {models.map((model,index) => {
                                                             return (
                                                                 <div key={model.key} style={{maxWidth:"none"}} className={'ProductCardContainer col-3'} onClick={function (e) {
                                                                     if(serial && !linked) {
                                                                         setSelected(model)
                                                                         $('.ProductCardContainer').removeClass('selectedModel')
                                                                         $(e.target).closest('.ProductCardContainer').addClass('selectedModel')
                                                                     }
                                                                 }}>
                                                                     <ProductCardDetail key={model.key} sku={SKU[0]} data={model} buttons={true}></ProductCardDetail>
                                                                 </div>
                                                             )
                                                         })}
                                                     </>
                                                 }
                                             </div>
                                             {serial && !alreadyLinked &&
                                                 <div className="row d-flex justify-content-end">
                                                     <button disabled={!selected || linked} className={'btn btnBo'} onClick={async function () {
                                                         await productService.linkToModel(serial[1].key, selected.key)
                                                         setLinked(true)
                                                     }}>{CacheExtension.getDataFromCache('Confirm')}</button>
                                                 </div>

                                             }
                                         </>
                                        }
                                    </div>
                                </div>

                                <div className={linked ? 'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepGestion3'))}</span>
                                    <div className={'my-2 container-fluid'}>
                                        <div className="mb-2">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input
                                                        className="custom-control-input"
                                                        type="radio"
                                                        name="options"
                                                        id="shop"
                                                        value="shop"
                                                        disabled={!linked}
                                                        checked={sendingType === 'shop'}
                                                        onChange={() => {
                                                            setShop('')
                                                            setForm(initialForm)
                                                            setAddress(false)
                                                            setSendingType('shop')
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="shop">{CacheExtension.getDataFromCache('Shop')}</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input
                                                        className="custom-control-input"
                                                        type="radio"
                                                        name="options"
                                                        id="eshop"
                                                        value="eshop"
                                                        disabled={!linked}
                                                        checked={sendingType === 'eshop'}
                                                        onChange={() => {
                                                            setShop('')
                                                            setForm(initialForm)
                                                            setAddress(false)
                                                            setSendingType('eshop')
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="eshop">E-shop</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input
                                                        className="custom-control-input"
                                                        type="radio"
                                                        name="options"
                                                        id="other"
                                                        value="other"
                                                        disabled={!linked}
                                                        checked={sendingType === 'other'}
                                                        onChange={() => {
                                                            setShop('')
                                                            setForm(initialForm)
                                                            setAddress(false)
                                                            setSendingType('other')
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="other">{CacheExtension.getDataFromCache('Other')}</label>
                                                </div>
                                            </div>

                                        </div>
                                            {sendingType ==='shop' &&
                                                <div className={'text-center mb-2'}>
                                                    <Dropdown
                                                        placeholder={CacheExtension.getDataFromCache('SelectShop')}
                                                        fluid
                                                        id={CreateGuid()}
                                                        search
                                                        selection
                                                        disabled={!linked}
                                                        options={Object.entries(shopsList).map((shop, i) => ({key: shop[0], value: shop[0], text: shop[1] }))}
                                                        onChange={(e, {value}) => {
                                                            const selected = Object.entries(shopsList).find(entry => entry[0] === value);
                                                            setShop(selected)
                                                        }}
                                                        style={{maxWidth : '300px'}} className={'mb-3'}/>
                                                </div>
                                            }
                                            {sendingType ==='eshop' &&
                                                <>
                                                    <div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="name">{CacheExtension.getDataFromCache('Firstname')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="name"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="lastname">{CacheExtension.getDataFromCache('Lastname')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="lastname"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group"  style={{width:'150px'}}>
                                                            <label htmlFor="title">{CacheExtension.getDataFromCache('PersonTitle')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="title"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className={"form-row"}>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="email">{CacheExtension.getDataFromCache('Email')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="email"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="phone">{CacheExtension.getDataFromCache('Phone')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="phone"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">{CacheExtension.getDataFromCache('Address')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="address"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="city">{CacheExtension.getDataFromCache('City')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="city"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="zipCode">{CacheExtension.getDataFromCache('ZipCode')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="zipCode"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="country">{CacheExtension.getDataFromCache('Country')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="country"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <Dropdown
                                                            defaultValue={'EN'}
                                                            fluid
                                                            selection
                                                            onChange={async (e, {value}) => {
                                                                setForm({
                                                                    ...form,
                                                                    defaultLanguage: value
                                                                })
                                                            }}
                                                            options={[{value : 'FR', text: 'FR'}, {value : 'EN', text: 'EN'}]}
                                                        />
                                                        <div className="form-group" style={{width:'150px'}}>
                                                            <label htmlFor="navision">Navision</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="navision"
                                                                   maxLength={16}
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="comment">{CacheExtension.getDataFromCache('Comment')}</label>
                                                            <textarea
                                                                className="form-control"
                                                                name="comment"
                                                                onChange={handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="date">{CacheExtension.getDataFromCache('SaleDate')}</label>
                                                            <input className="form-control" type='date' name="date" onChange={handleChange} />
                                                        </div>
                                                        <div className="row d-flex justify-content-end">
                                                            <button className="btn btnBo" onClick={() => {
                                                                setAddress(true)
                                                            }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        {sendingType === 'other' &&
                                            <>
                                                <div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="name">{CacheExtension.getDataFromCache('Firstname')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="name"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="lastname">{CacheExtension.getDataFromCache('Lastname')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="lastname"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group"  style={{width:'150px'}}>
                                                        <label htmlFor="title">{CacheExtension.getDataFromCache('PersonTitle')}</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="title"
                                                               onChange={handleChange}/>
                                                    </div>
                                                    <div className={"form-row"}>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="email">{CacheExtension.getDataFromCache('Email')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="email"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="phone">{CacheExtension.getDataFromCache('Phone')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="phone"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="address">{CacheExtension.getDataFromCache('Address')}</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="address"
                                                               onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="city">{CacheExtension.getDataFromCache('City')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="city"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="zipCode">{CacheExtension.getDataFromCache('ZipCode')}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="zipCode"
                                                                   onChange={handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="country">{CacheExtension.getDataFromCache('Country')}</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="country"
                                                               onChange={handleChange}/>
                                                    </div>
                                                    <Dropdown
                                                        defaultValue={'EN'}
                                                        fluid
                                                        selection
                                                        onChange={async (e, {value}) => {
                                                            setForm({
                                                                ...form,
                                                                defaultLanguage: value
                                                            })
                                                        }}
                                                        options={[{value : 'FR', text: 'FR'}, {value : 'EN', text: 'EN'}]}
                                                    />
                                                    <div className="form-group"  style={{width:'150px'}}>
                                                        <label htmlFor="navision">Navision</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="navision"
                                                               maxLength={16}
                                                               onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="comment">{CacheExtension.getDataFromCache('Comment')}</label>
                                                        <textarea
                                                            className="form-control"
                                                            name="comment"
                                                            onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="date">{CacheExtension.getDataFromCache('SaleDate')}</label>
                                                        <input className="form-control" type='date' name="date" onChange={handleChange} />
                                                    </div>
                                                    <div className="row d-flex justify-content-end">
                                                        <button className="btn btnBo" onClick={() => {
                                                            setAddress(true)
                                                        }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className={(shop || address) ? 'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepGestion4'))}</span>
                                    <div className={'stockSectionContent justify-content-end mb-2'}>
                                        <ConfirmModalComponent disabled={!(shop || address)} html={modalHtml(SKU[0], serial[0], shop[1] ?? null)} icon={<span>{CacheExtension.getDataFromCache(shop ? 'SendShopModal' : 'Send')}</span>} title={CacheExtension.getDataFromCache(shop ? 'SendShopModal' : 'Send')} action={async function (e) {
                                            if(shop){
                                                await productService.send(serial[1].key,shop[0]);
                                            }
                                            if(address){
                                                await productService.sellAndCreate(serial[1].key,form);
                                            }
                                            window.location.reload();
                                        }}></ConfirmModalComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </m.div>
            }
        </>
    );
}

