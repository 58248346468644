import React, {useEffect, useRef, useState} from "react";
import ModelService from "../Services/Bll/ModelService";
import {motion as m} from "framer-motion";
import {IconActionButton} from "../Components/IconActionButton";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import {Dropdown} from 'semantic-ui-react'
import {HeaderComponent} from "../Components/HeaderComponent";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import {ImportComponent} from "../../StarterKit/Components/ImportComponent";
import SealVectorService from "../Services/Bll/SealVectorService";
import FileModel from "../../StarterKit/Model/FileModel";
import ProductService from "../Services/Bll/ProductService";
import MediaService from "../../StarterKit/Service/Bll/MediaService";
import {ConfirmModalComponent} from "../../StarterKit/Components/ConfirmModalComponent";
import QR from "../Picture/QR.png"
import token from "../Picture/token.png"

export default function StockPequignet() {
    const settingService = new SettingService();
    const sealVectorService = new SealVectorService();
    const productService = new ProductService();
    const mediaService = new MediaService();
    const service = new ModelService();

    const [data, setData] = useState(null);
    const [post, setPost] = React.useState(null);
    const [noSeal, setSealAvailability] = React.useState(true);
    const [SKU, setSKU] = useState('');
    const [serial, setSerial] = useState('');
    const [nbSeal, setNbSeal] = useState({});
    const [context, setContext] = useState('');

    const [resume, setResume] = useState(false);
    const [step, setStep] = useState(1);
    const [product, setProduct] = useState('');
    const [sealNumber, setSealNumber] = useState('');
    const [tokenNumber, setTokenNumber] = useState('');
    const [sealVectorAsset, setSealVectorAsset] = useState('');
    const [tokenAsset, setTokenAsset] = useState('');
    const [loading, setLoading] = useState('');

    let sealState = {}
    const serialInput = useRef();

    const handleChangeSerial = (newValue) => {
        serialInput.current.value = newValue;
        serialInput.current.dispatchEvent(new Event('change', { bubbles: true }));
    };

    useEffect(() => {
        service.listSkus().then(function (result) {
            let res = result.getData();
            setData(res)
        })



        settingService.getFromGroup('productType').then(function (result) {
            setPost(Object.keys(result.getData()))
            Object.keys(result.getData()).forEach(function (el) {
                sealVectorService.list(el).then(function (res) {
                    sealState[el] = {
                        'available': res.getData().sealVectors.length,
                        'consumed': res.getData().sealVectorsConsumed.length
                    }
                    if (res.getData().sealVectors.length === 0){
                        setSealAvailability(false);
                    }
                    setNbSeal({...nbSeal,...sealState})
                })
            })
        })
    }, [])

    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    async function addnewStock(model,serial,sealnumber,sealToken,authPdf) {
        var modelService = new ModelService();
        var result = await modelService.add(model,serial,sealnumber,sealToken,authPdf)
        window.location.reload()
    }

    async function saveSealVector(seal) {
        let name = "M-"+SKU[0].replace(/\//g, "-")+"-"+serial+".dxf"
        await sealVectorService.readDxf(seal, name)
    }

    async function saveAuthCard(seal) {
        let name = "C-"+SKU[0].replace(/\//g, "-")+"-"+serial+".dxf"
        await mediaService.read(tokenAsset, name)
    }

    async function  submit(formName) {
        $("body").css('cursor','wait');
        var sealVectorService = new SealVectorService();
        var fileModel = new FileModel()
        var file = $('#'+ formName+'_form input[type="file"]')[0].files[0];
        if (file) {
            fileModel.title = file.name
            fileModel.file = file
            await sealVectorService.import(fileModel,formName);
            window.location.reload()
        }
        $("body").css('cursor','default');
    }

    function modalHtml(sku, serial) {
        return (
            <div>
                <div><strong>{CacheExtension.getDataFromCache("SKU")}</strong>{` ${sku}`}</div>
                <div><strong>{CacheExtension.getDataFromCache("Serial")}</strong> {` ${serial}`}</div>
            </div>
        )
    }

    return (
        <>
            {data &&
                <m.div className={"wrapper"}>

                    <div id={"page"}>
                        <HeaderComponent logo={'/pictures/Logo_Pequignet.png'} title={CacheExtension.getDataFromCache('Stock').replace('%%1%%',sessionStorage['lastname'])}></HeaderComponent>
                        <div id="content">
                            <div id={"page"}>
                                <div className={'stockSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepProd0'))}</span>
                                    <div className={'stockSectionContent'}>
                                        <div className={'d-flex col-12 justify-content-end'}>
                                            { (post && nbSeal) && (
                                                post.map( (index) =>
                                                        <div key={index} className={'form-group col-3 mr-3'}>
                                                            {nbSeal[index] &&
                                                                <span className={nbSeal[index]['available'] > nbSeal[index]['consumed'] ? 'nbSeal nbSealGreen' : 'nbSeal nbSealRed'}>{CacheExtension.getDataFromCache('Availbale') + ' '+ nbSeal[index]['available']}</span>
                                                            }
                                                                <form
                                                                id={index+'_form'}
                                                                onChange={() => submit(index)}
                                                                action="">
                                                                <ImportComponent formName = {CacheExtension.getDataFromCache("ImportWithReplace").replace("%%1%%",CacheExtension.getDataFromCache(index))} className={'col-12'} form = {false} />
                                                            </form>
                                                        </div>
                                                )
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={Object.keys(data).length > 0 && noSeal ? 'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepProd1'))}</span>
                                    <div className={'stockSectionContent justify-content-start'}>
                                        {Object.keys(data).length > 0 && noSeal ?(
                                            <div>
                                                <button disabled={!SKU || step>1 || !SKU[1].serials || Object.keys(SKU[1].serials).length === 0} onClick={async function () {
                                                    setResume(!resume)
                                                    setStep(1)
                                                    setSerial('')
                                                    setSealNumber('');
                                                    setTokenAsset('');
                                                    setSealVectorAsset('');
                                                    setProduct('');
                                                }} style={{width:"215px"}} className={'btn btnBo my-3'}>{CacheExtension.getDataFromCache(resume ? 'AddToggle' : 'ResumeToggle')}</button>
                                                <div className={'filters d-flex align-items-center'}>
                                                    <div className={'form-group mr-1'}>
                                                        <p className={'mb-1'}>{CacheExtension.getDataFromCache("SKU")}</p>
                                                        <Dropdown
                                                            style={{width:"215px"}}
                                                            placeholder={CacheExtension.getDataFromCache('SelectSKU')}
                                                            fluid
                                                            search
                                                            selection
                                                            onChange={(e, { value }) => {
                                                                const selected = Object.entries(data).find(entry => entry[0] === value);
                                                                setSKU(selected)
                                                                setStep(1)
                                                                setResume(false)
                                                                setSerial('')
                                                                setSealNumber('');
                                                                setTokenAsset('');
                                                                setSealVectorAsset('');
                                                                setProduct('');
                                                            }}
                                                            options={data ? Object.entries(data).map((value, i) => ({ key: i, value: value[0], text: value[0] })) : []}
                                                        />
                                                    </div>
                                                    {resume ?
                                                        <div className={'form-group mr-3'}>
                                                            <p className={'mb-1'}>{CacheExtension.getDataFromCache("Serial")}</p>
                                                            <Dropdown
                                                                style={{width : '215px'}}
                                                                placeholder={CacheExtension.getDataFromCache('SelectSerial')}
                                                                fluid
                                                                search
                                                                selection
                                                                onChange={(e, { value }) => {
                                                                    setSerial(value);
                                                                    setSealNumber(SKU[1].serials[value].sealVector ? SKU[1].serials[value].sealVector : '');
                                                                    setTokenAsset(SKU[1].serials[value].authCardAsset ? SKU[1].serials[value].authCardAsset : '');
                                                                    setSealVectorAsset(SKU[1].serials[value].sealVectorAsset ? SKU[1].serials[value].sealVectorAsset : '');
                                                                    setProduct(SKU[1].serials[value].key);
                                                                    setStep(SKU[1].serials[value].sealVector && SKU[1].serials[value].authCardAsset ? 3 : 2);
                                                                }}
                                                                options={Object.entries(SKU[1].serials).map((value, i) => ({ key: i, value: value[0], text: value[0] }))}
                                                            />
                                                        </div> :
                                                        <div className={'form-group mr-3'}>
                                                            <p className={'mb-1'}>{CacheExtension.getDataFromCache("Serial")}</p>
                                                            <input  ref={serialInput} maxLength={7} disabled={!SKU} type="text" style={{width : '200px'}} className="form-control"  onChange={function (e) {
                                                                if ($(e.target).val() !== ''){
                                                                    setSerial($(e.target).val())
                                                                }
                                                            }}/>
                                                        </div>
                                                    }
                                                    {!resume && SKU &&
                                                        <button disabled={!SKU || step>1} onClick={async function () {
                                                            handleChangeSerial(SKU[1].next ? SKU[1].next : "0001")
                                                            setSerial(SKU[1].next ? SKU[1].next : "0001")
                                                        }} className={'btn btnBo mt-3'}>{CacheExtension.getDataFromCache('NextSerial') + ' (' + (SKU[1].next ? SKU[1].next : "0001") + ')'}</button>
                                                    }
                                                </div>
                                            </div>
                                        ) : (CacheExtension.getDataFromCache(Object.keys(data).length === 0 ? 'NoModelsToShow' : 'NoSealVectors'))}
                                    </div>
                                    <div className={'stockSectionContent justify-content-end mb-2'}>
                                        {!resume &&
                                            <button disabled={!SKU || !serial || step!=1} onClick={async function () {
                                                const res = await service.register(serial, SKU[0])
                                                if (res.code === 0) {
                                                    setStep(2)
                                                    setProduct(res.getData().product)
                                                }
                                            }} className={'btn btnBo mt-3'}>{CacheExtension.getDataFromCache('Validate')}</button>
                                        }
                                    </div>
                                </div>
                                <div className={step < 2 || !product ? 'stockSection disabledSection' : 'stockSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepProd2'))}</span>
                                    <div className={'justify-content-end my-2'}>
                                        <div className={'row text-center mb-2'}>
                                            <div className={'col-12 d-flex justify-content-end'}>
                                                <div className="d-flex flex-column">
                                                    {!sealVectorAsset &&
                                                        <button disabled={step < 2 || !product} className={'btn btnBo mb-2'} onClick={async function () {
                                                            var seal = await sealVectorService.associateNext(product, 'watch')
                                                            if (seal.getData().asset && seal.getData().key){
                                                                setSealNumber(seal.getData().key)
                                                                setSealVectorAsset(seal.getData().asset)
                                                                const updated = { ...nbSeal };
                                                                updated.watch.available -= 1;
                                                                updated.watch.consumed += 1;
                                                                setNbSeal(updated);
                                                            }
                                                        }}>{CacheExtension.getDataFromCache('GetSealVector')}</button>
                                                    }
                                                    {!tokenAsset && !loading &&
                                                        <button disabled={step < 2 || !sealNumber || !product} className={'btn btnBo'} onClick={async function () {
                                                            setLoading(true)
                                                            var token = await productService.getToken(sealNumber,SKU[0], product)
                                                            if (token.getData().key && token.getData().asset){
                                                                setTokenNumber(token.getData().key)
                                                                setTokenAsset(token.getData().asset)
                                                                const updated = { ...nbSeal };
                                                                updated.authCard.available -= 1;
                                                                updated.authCard.consumed += 1;
                                                                setNbSeal(updated);
                                                                setStep(3)
                                                            }
                                                            setLoading(false)


                                                        }}>{CacheExtension.getDataFromCache('GetToken')}</button>
                                                    }
                                                </div>
                                            </div>
                                            {sealVectorAsset &&
                                                <div className="col-6">
                                                    <div className="card w-50 mx-auto">
                                                        <div className="card-header d-flex justify-content-between align-items-center">
                                                            <div>{CacheExtension.getDataFromCache('SealVector')}</div>
                                                            <div>
                                                                <IconActionButton  action={async () => await saveSealVector(sealVectorAsset)} state={true} icon={<FontAwesomeIcon className={"actionLink"} icon="fas fa-download"/>}  tooltip={CacheExtension.getDataFromCache('Download')}/>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <img className="previewImg" src={QR} onClick={async () => await saveSealVector(sealVectorAsset)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-6">
                                            {loading ? <div className="spinner-border customSpinner" role="status"></div> :
                                                tokenAsset &&
                                                    <div className="card w-50 mx-auto">
                                                        <div className="card-header d-flex justify-content-between align-items-center">
                                                            <div>{CacheExtension.getDataFromCache('authCard')}</div>
                                                            <div>
                                                                <IconActionButton action={async () => await saveAuthCard(tokenAsset)} state={true} icon={<FontAwesomeIcon icon="fas fa-download"/>} tooltip={CacheExtension.getDataFromCache('Download')}/>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <img className="previewImg" src={token} onClick={async function () {
                                                                await saveAuthCard(tokenAsset)
                                                            }}/>
                                                        </div>
                                                    </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(step === 3 || tokenAsset !== '') ?'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepProd3'))}</span>
                                    <div className={'stockSectionContent justify-content-end mb-2'}>
                                        <button disabled={step != 3} className={'btn btnBo'} onClick={async function () {
                                                setStep(4)
                                            }}>{CacheExtension.getDataFromCache('ConfirmSealVectorAndCard')}</button>
                                    </div>
                                </div>
                                <div className={step === 4 ?'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepProd4'))}</span>
                                    <div className={'stockSectionContent justify-content-end mb-2'}>
                                        <ConfirmModalComponent disabled={step !== 4} html={modalHtml(SKU[0], serial)} icon={<span>{CacheExtension.getDataFromCache('AddProduct')}</span>} title={CacheExtension.getDataFromCache('AddProduct')} action={async function (e) {
                                            await productService.validate(product)
                                            window.location.reload();
                                        }}></ConfirmModalComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </m.div>
            }
        </>
    );
}

