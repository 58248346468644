import React, {useEffect, useState} from "react";
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import {HeaderComponent} from "../Components/HeaderComponent";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import ProductService from "../Services/Bll/ProductService";
import MediaService from "../../StarterKit/Service/Bll/MediaService";
import {IconActionButton} from "../Components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ConfirmModalComponent} from "../../StarterKit/Components/ConfirmModalComponent";

export default function ManageInvoices() {

    const service = new ProductService();
    const mediaService = new MediaService();
    const [data, setData] = useState(null);

    useEffect(() => {
        service.listState('inVerification').then(function (result) {
            let res = result.getData();
            setData(res);
        })
    }, []);

    function modalHtml(serial) {
        return (
            <div>
                <div><strong>{CacheExtension.getDataFromCache("Serial")}</strong>{` ${serial}`}</div>
            </div>
        )
    }

    return (
        <>
                <m.div className={"wrapper"}>
                    <div id={"page"}>
                        <HeaderComponent logo={'/pictures/Logo_Pequignet.png'}
                                         title={CacheExtension.getDataFromCache('InvoiceManager')}></HeaderComponent>
                        <div id="content">
                            <div id={"page"} className="d-flex justify-content-center">
                                <div className="container w-50" >
                                    {data && data.products.length !== 0 ?
                                        <>
                                            <div className="row mx-0 py-2" style={{borderBottom: '1px solid #dee2e6'}}>
                                                <div className="col-4">
                                                    <strong>{CacheExtension.getDataFromCache('Serial')}</strong>
                                                </div>
                                            </div>
                                            <div className="fullSize p-0">
                                                {data.products.map((product, i) => (
                                                        product.invoice && product.invoice != 'Refused' &&
                                                        <div key={i} className={'row mx-0 py-2 ' + (i % 2 === 0 ? 'oddTableLine' : 'evenTableLine')}>
                                                            <div className="col-3">
                                                                <p>{product.serial}</p>
                                                            </div>
                                                            <div className="col-4">
                                                                <IconActionButton action={async () => await mediaService.read(product.invoice)} state={true} icon={<FontAwesomeIcon className={"actionLink"} icon="fas fa-eye"/>}  />
                                                            </div>
                                                            <div className="col-5 d-flex justify-content-end">
                                                                <ConfirmModalComponent class={"mx-1"} html={modalHtml(product.serial)} icon={<span>{CacheExtension.getDataFromCache('Validate')}</span>} title={CacheExtension.getDataFromCache('Validate')} action={async () => {
                                                                    await service.validateInvoice(product.key)
                                                                    window.location.reload()
                                                                }}></ConfirmModalComponent>
                                                                <ConfirmModalComponent class={"mx-1"} html={modalHtml(product.serial)} icon={<span>{CacheExtension.getDataFromCache('Refuse')}</span>} title={CacheExtension.getDataFromCache('Refuse')} action={async () => {
                                                                    await service.refuseInvoice(product.key)
                                                                    window.location.reload()
                                                                }}></ConfirmModalComponent>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </> :
                                        <p className="text-center">{CacheExtension.getDataFromCache('NoInvoices')}</p>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </m.div>
        </>
    );
}

