import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import ProductService from "../Services/Bll/ProductService";
import {IconActionButton} from "./IconActionButton";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import MediaService from "../../StarterKit/Service/Bll/MediaService";
import {SendToShopModal} from "./SendToShopModal";




export const ProductListModal = (props) => {
    const [data, setData] = useState(null);
    var productService = new ProductService()
    var mediaService = new MediaService()
    const [shops, setShops] = useState(null);



    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    var id = CreateGuid()

    function openModal() {
        var container__modalSring = ".modal_" + id;
        var modale__showstring = ".backgroundmodal_" + id
        $(container__modalSring).modal('show')
        $(modale__showstring).css({'opacity' : 0.5,'display' : 'block'})
        productService.listFromModel(props.model).then(function (result) {
            setData(result.getData())
        })
    }

    function closeModal(id) {
        var container__modalSring = ".modal_" + id;
        var modale__showstring = ".backgroundmodal_" + id
        $(container__modalSring).modal('hide')
        $(modale__showstring).css({'display' : 'none'})
    }
    return (
        <>
            <div>
                <button className={"btn actionLink"} onClick={function () {openModal(props)}} data-toggle="tooltip" data-placement="top" title={CacheExtension.getDataFromCache('ProductListModal')}>{props.icon}
                </button>
                <div>
                    <div id={'skModal'} className={"banner modal ModalQRCode " + "modal_" + id }>
                        <div className={"banner modal-dialog"}>
                            <div className={"banner modal-content"}>
                                <button type="button" className="closeModal close" data-dismiss="modal"
                                        aria-label="Close"><span aria-hidden="true"     onClick={() => closeModal(id)}
                                >×</span></button>
                                <h1 className={"QrTitle"}>{CacheExtension.getDataFromCache('ProductList')}</h1>
                                <div className={'modal-body'}>
                                    <div className={'d-flex flex-column'}>
                                        { (data) ? (
                                            data.map( (index) =>
                                                <>
                                                    <div className={'d-flex justify-content-between align-items-center'}>
                                                        <a>{index.serial}</a>
                                                        <div className={'d-flex'}>
                                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('GetToken')}  action={async () => {
                                                                await mediaService.read(index.token)
                                                            }  } state={true} icon={<FontAwesomeIcon icon="fa-solid fa-file-pdf" />}></IconActionButton>
                                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('SellModal')} action={async () => {
                                                                await productService.sell(index.key)
                                                                window.location.reload()
                                                            }  } state={true} icon={<FontAwesomeIcon icon="fa-solid fa-dollar-sign" />}></IconActionButton>
                                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('LostModal')} action={async () => {
                                                                await productService.lost(index.key)
                                                                window.location.reload()
                                                            }  } state={true} icon={<FontAwesomeIcon icon="fa-solid fa-person-circle-question" />}></IconActionButton>
                                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('DestroyedModal')} action={async () => {
                                                                await productService.destroyed(index.key)
                                                                window.location.reload()
                                                            }  } state={true} icon={<FontAwesomeIcon icon="fa-solid fa-heart-crack" />}></IconActionButton>
                                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('SavModal')} action={async () => await console.log('SAV ' + index.key)  } state={true} icon={<FontAwesomeIcon icon="fa-solid fa-wrench" />}></IconActionButton>
                                                            <SendToShopModal  product={index.key} icon={<FontAwesomeIcon icon="fa-solid fa-truck-fast"></FontAwesomeIcon>}></SendToShopModal>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        ) : ( ''
                                        )
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => closeModal(id)} className={"modal-backdrop " + "backgroundmodal_" + id}></div>
                </div>
            </div>
        </>
    );
}

ProductListModal.propTypes = {
    model : PropTypes.string.isRequired,
    icon : PropTypes.element.isRequired,
}
