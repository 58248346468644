import React from "react";
import '../styles/menu.css';
import '../StarterKit/css/message.css';
import '../styles/header.css';
import logo512 from "../res/logo512.png"
import CacheExtension from "../StarterKit/Filter/CacheExtension";

export const Nav = (props) => {
    var lastname = sessionStorage['lastname']
    var pathname = window.location.pathname
    var storageLanguage = JSON.parse(localStorage.getItem('language'));
    var storageLanguageKeys =  Object.keys(storageLanguage);
    var storageLanguageValue =  Object.values(storageLanguage);
    var logo = logo512;

    return (
        <>
            <nav id={"sidebar"} className={"color-nav"}>
                <div className="sidebar-header">
                    <a href="" data-toggle="tooltip" data-placement="right">
                        <h3>
                            <img src={logo} width={60}/>
                        </h3>
                        <p>
                            <img src="/medias/-logoSmallWhite" width={40} />
                        </p>
                    </a>
                </div>
                <ul className="list-unstyled components">
                    <li className={(pathname.includes('admin')) ? 'active' : ""}>
                        <a data-toggle="collapse"
                           href="#appDriving"
                           role="button"
                           aria-expanded="false"
                           aria-controls="collapseExample">
                            <i className="fas fa-cog"></i>
                            <span>{CacheExtension.getDataFromCache("MenuAdministration")}</span>
                            <i className="fa-solid fa-chevron-down"></i>
                        </a>
                    </li>
                    <div className="administration" id="appDriving">
                        <li className={(pathname.includes('imports')) ? 'active' : ""}>
                            <a href="/admin/imports">
                                <i className="ml-3 fas fa-users-cog"></i>
                                <span>{CacheExtension.getDataFromCache("MenuAdminImport")}</span>
                            </a>
                        </li>
                        <li className={(pathname.includes('users')) ? 'active' : ""}>
                            <a href="/admin/users">
                                <i className="ml-3 fas fa-users-cog"></i>
                                <span>{CacheExtension.getDataFromCache("MenuAdminUsers")}</span>
                            </a>
                        </li>
                        <li className={(pathname.includes('rights')) ? 'active' : ""}>
                            <a href="/admin/rights">
                                <i className="ml-3 fas fa-user-lock"></i>
                                <span>{CacheExtension.getDataFromCache("MenuAdminRights")}</span>
                            </a>
                        </li>
                        <li className={(pathname.includes('zones')) ? 'active' : ""}>
                            <a href="/admin/zones">
                                <i className="ml-3 fas fa-dungeon"></i>
                                <span>{CacheExtension.getDataFromCache("MenuAdminZones")}</span>
                            </a>
                        </li>
                        <li className={(pathname.includes('settings')) ? 'active' : ""}>
                            <a href="/admin/settings">
                                <i className="ml-3 fas fa-database"></i>
                                <span>{CacheExtension.getDataFromCache("MenuAdminSettings")}</span>
                            </a>
                        </li>
                        <li className={(pathname.includes('groups')) ? 'active' : ""}>
                            <a href="/admin/groups">
                                <i className="ml-3 fas fa-layer-group"></i>
                                <span>{CacheExtension.getDataFromCache("MenuAdminGroups")}</span>
                            </a>
                        </li>
                    </div>
                    <li className="mt-4">
                        <a href="/logout">
                            <i className="ml-3 fas fa-sitemap"></i>
                            <span>{CacheExtension.getDataFromCache("logout")}</span>
                        </a>
                    </li>
                    <li className="mt-4 text-center">
                        <span>
                            <select defaultValue={sessionStorage['language']}    onChange={() => changeLanguage()} className="custom-select" id="languageSelector" >
                                { storageLanguageKeys.map((form, index) =>
                                    (storageLanguageKeys[index]) ? (
                                        <option key={index}  value={storageLanguageKeys[index]}>{storageLanguageValue[index].text}</option>
                                    ) : ( 'ok'
                                    )
                                )}
                            </select>

                        </span>
                    </li>
                </ul>

            </nav>
            <div className={"modal-backdrop fade"}>

            </div>
        </>
    );
}

function changeLanguage(props) {
    var languageSelector = document.getElementById('languageSelector')
    var languagestorage = sessionStorage['language']
    if (languagestorage !== languageSelector.value) {
        localStorage.clear();
        sessionStorage.setItem("language", languageSelector.value);
        location.reload();
    }
}

