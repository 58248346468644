import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";


export default class ApiModel extends BaseApiService {


    async list() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/models");
        return await this.callApi();
    }

    async listAll() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/list_all/models");
        return await this.callApi();
    }
    async add(model,serial,sealnumber,sealToken,authPdf) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody({
            serial,
            sealVectorPr : sealnumber,
            sealVectorCard : sealToken,
            authPdf
        });
        this.setApiRoute(sessionStorage['language'] + "/models/"+model+'/register');
        return await this.callApi();
    }

    async import(file) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedFileInBody();
        this.setFile(file);
        this.setApiRoute(sessionStorage['language'] + "/models/import?force=true");
        return await this.callApi();
    }

    async listSkus() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/skus/details");
        return await this.callApi();
    }

    async listFromSku(sku) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/models/sku/" + sku);
        return await this.callApi();
    }

    async register(serial,sku) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody({
            serial,
            sku
        });
        this.setApiRoute(sessionStorage['language'] + "/models/register");
        return await this.callApi();
    }

}
