import {Collapse} from 'react-collapse';
import PropTypes from 'prop-types';
import React from "react";
import '../css/collapse.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class CollapseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {collapseState: false};
        this.id = this.CreateGuid()
    }

    toggleCollapse() {
        this.setState({collapseState : !this.state.collapseState});
        if (!this.state.collapseState){
            $('#'+this.id).find('.chevron').addClass('rotateUp');
        }else {
            $('#'+this.id).find('.chevron').removeClass('rotateUp');
        }
    }

    CreateGuid() {
        function _p8(s) {
            var p = (Math.random().toString(16)+"000000000").substr(2,8);
            return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }


    render() {
        var that = this
        return (
            <>
                <div id={this.id}>
                    <div className={'position-relative'}>
                        <button className={that.props.buttonClass} onClick={function () {
                            that.toggleCollapse()
                        }} style={{fontSize: 'x-large'}}>{that.props.buttonText}</button>
                        <div style={{position:"absolute",right:"2%",top:"50%", transform: 'translateY(-50%)'}}><span className={'chevron'}><FontAwesomeIcon icon="fa-solid fa-chevron-down" /></span></div>
                    </div>

                    <Collapse isOpened={that.state.collapseState}>
                        {that.props.dom}
                    </Collapse>
                </div>
            </>
        );
    }
};

CollapseComponent.propTypes = {
    buttonClass : PropTypes.string.isRequired,
    buttonText : PropTypes.string.isRequired,
    dom : PropTypes.element.isRequired,
};

