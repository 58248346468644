import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiProduct from "../Api/ApiProduct";

export default class ProductService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiProduct();
        this.#helper = new HelperService();
    }


    async destroyed(key) {
        const result = await this.#api.destroy(key);
        return result;
    }

    async received(key) {
        var shop = sessionStorage['firstname']
        if (shop){
            const result = await this.#api.received(shop,key);
            return result;
        }
    }

    async lost(key) {
        const result = await this.#api.lost(key);
        return result;
    }

    async send(key,shop) {
        const result = await this.#api.send(key,shop);
        return result;
    }

    async stolen(key) {
        const result = await this.#api.stolen(key);
        return result;
    }

    async sell(product, address) {
        const result = await this.#api.sell(product, address);
        return result;
    }

    async sellAndCreate(product, form) {
        const result = await this.#api.sellAndCreate(product, form);
        return result;
    }

    async read(gtin, serial){
        const result = await this.#api.read(gtin, serial);
        return result;
    }

    async listFromModel(key){
        const result = await this.#api.listFromModel(key);
        return result;
    }

    async listState(state){
        const result = await this.#api.listState(state);
        return result;
    }

    async getToken(seal,sku,product){
        const result = await this.#api.getToken(seal,sku,product);
        return result;
    }

    async validate(product) {
        const result = await this.#api.validate(product);
        return result;
    }

    async validateInvoice(product) {
        const result = await this.#api.validateInvoice(product);
        return result;
    }

    async refuseInvoice(product) {
        const result = await this.#api.refuseInvoice(product);
        return result;
    }

    async linkToModel(product, model) {
        const result = await this.#api.linkToModel(product, model);
        return result;
    }
}
