import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/register.css';
import logo from "../../res/logo512.png"
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LoginService from "../Service/Bll/LoginService";
import CacheExtension from "../Filter/CacheExtension";
import Consts from "../../App/Consts";

export default function Login() {
    const [useProduct, setUseProduct] = useState('');
    const [product, setProduct] = useState([]);
    const navigate = useNavigate();
    var service = new LoginService();
    var url = JSON.parse(localStorage.getItem(Consts.GROUP_HOME));
    React.useEffect(() => {
        if (sessionStorage['isLogged'] !== undefined && sessionStorage['isLogged'] === 'yes'){
            navigate(url[sessionStorage['profile']].text)
        }
    })



    const state = {
        name: '',
        password: '',
    }

    const handleChangeLogin = event => {
        state.name = event.target.value;
    }
    const handleChangePassword = event => {
        state.password = event.target.value;
    }

    const  handleSubmit = async event => {
        event.preventDefault();
        const user = {
            username: document.getElementById('sk_username').value,
            password: document.getElementById('sk_password').value,
            service: "back"
        };
        var result = await service.login(user);
        if (result.getCode() === 0) {
            if (url) {
                navigate(url[sessionStorage['profile']].text)
            }
        }
    }
    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                <div className="row mt-5">
                    <div className="offset-1 offset-sm-2 offset-xl-3 col-10 col-sm-8 col-xl-6 tableBackground mt-5">
                        <div className="login-form text-center pt-3 pb-3">
                            <div id="header">
                                <div className="row cm-color-title">
                                    <div className="col-12 text-center">
                                        <img
                                            src={logo}
                                            className="logoHeader mb-3 mt-3"
                                            style={{height: 150}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <form  onSubmit={handleSubmit} >
                                        <div className="form-group">
                                            <label htmlFor="sk_username" className="required">
                                                {CacheExtension.getDataFromCache('LoginUsername')}
                                            </label>
                                            <input
                                                onChange={handleChangeLogin}
                                                type="text"
                                                id="sk_username"
                                                required="required"
                                                name="name"
                                                autoFocus="autofocus"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                title=""
                                                className="form-control"
                                                data-original-title="{{'MandatoryField'|cache }}"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="sk_password" className="required">
                                                {CacheExtension.getDataFromCache('LoginPassword')}
                                            </label>
                                            <input
                                                type="password"
                                                onChange={handleChangePassword}
                                                id="sk_password"
                                                name="password"
                                                required="required"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                title=""
                                                className="form-control"
                                                data-original-title="{{'MandatoryField'|cache }}"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button id="login_form_save" className="button submitform btn">
                                                {CacheExtension.getDataFromCache('LoginLogButton')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <div className="row">
                                        <div className="col-6 d-none d-sm-block">
                                            <a
                                               className="actionLink link">{"LoginToken cache"}</a>
                                        </div>
                                        <div className="col-6 d-none d-sm-block">
                                            <a href="{ path('forgotPassword') }"
                                               className="link">{"LoginForgotPassword cache"}</a>
                                        </div>
                                        <div className="col-12 text-center d-block d-sm-none">
                                            <a
                                               className="actionLink link">{"LoginToken cache"}</a>
                                        </div>
                                        <div className="col-12 text-center d-block d-sm-none">
                                            <a href="{ path('forgotPassword') }"
                                               className="link">{"LoginForgotPassword cache"}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    );
}


